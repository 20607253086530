
import Vue from "vue";

export default Vue.extend({
  name: "ProfileMenu",
  props: ["user"],
  data() {
    return {
      // Profile menu actions
      buttons: [
        {
          name: (this as any).$t("profile.menuItemsMentorProfile"),
          icon: "profile",
          action: () => console.log("Mentor Profile clicked!")
        },
        {
          name: (this as any).$t("profile.menuItemsAttachments"),
          icon: "attachment",
          action: () => console.log("Attachments clicked!")
        },
        {
          name: (this as any).$t("profile.menuItemsAttendees"),
          icon: "3users",
          action: () => console.log("Attendees clicked!")
        },
        {
          name: (this as any).$t("profile.menuItemsFinished"),
          icon: "time-circle",
          action: () => console.log("Finished clicked!")
        },
        {
          name: (this as any).$t("profile.menuItemsAttendeesOnly"),
          icon: "privacy",
          action: () => console.log("Attendees only clicked!")
        }
      ]
    };
  }
});
