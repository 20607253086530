
import Vue from "vue";
import ProfileMenu from "@/components/Profile/ProfileMenu.vue";
import Pagination from "@/components/Pagination.vue";
import MentorcastData from "@/components/Mentorcast/MentorcastData.vue";
import Comments from "@/components/Comments/Comments.vue";
import RecommendedMentors from "@/components/Mentor/RecommendedMentors.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "MentorcastDetailsPage",
  components: {
    ProfileMenu,
    Pagination,
    MentorcastData,
    Comments,
    RecommendedMentors
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      mentorcast: "mentorcast/getMentorcast",
      comments: "comment/getComments"
    })
  },
  mounted: function(): void {
    this.$store
      .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("comment/GET_COMMENTS", {
          type: "mentorcast",
          id: this.$route.params.id,
          page: 1
        });
      });
  }
});
