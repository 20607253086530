
import Vue from "vue";
import Comment from "@/components/Comments/Comment.vue";
import CommentForm from "@/components/Comments/CommentForm.vue";
import { mapGetters } from "vuex";
import LoadMore from "@/components/Partials/LoadMore.vue";
import Socket from "@/services/Socket";

export default Vue.extend({
  name: "Comments",
  components: {
    Comment,
    CommentForm,
    LoadMore
  },
  props: {
    isMentorcast: {
      type: Boolean
    },
    formTitle: {
      type: String
    },
    canComment: {
      type: Boolean
    }
  },
  data() {
    return {
      data: [] as any,
      page: 1,
      load_more: false,
      last_page: 0,
      clap: 0,
      disagree: 0,
      sceptic: 0,
      findIndex: null,
      filter_type: null,
      tabs: ["Recent", "MostAgreed"],
      currentTab: "Recent"
    };
  },
  computed: {
    ...mapGetters({
      comments: "comment/getComments",
      profile: "profile/getProfile"
    })
  },
  mounted() {
    if (this.isMentorcast) {
      const mentorcastId = this.$route.params.id;
      Socket().on(`mentorcast.${mentorcastId}.comments`, data => {
        if (this.profile.id !== data.user_id) {
          this.data.unshift(data);
          this.$store.dispatch(
            "mentorcast/GET_MENTORCAST_COMMENTS_COUNT",
            mentorcastId
          );
        }
      });
      Socket().on(`mentorcast.${mentorcastId}.likes`, data => {
        if (this.profile.id !== data.user_id) {
          this.updateLike(data.id, data.counts);
        }
      });
    }
    this.getComments(1);
  },
  beforeDestroy() {
    const mentorcastId = this.$route.params.id;
    const events = [
      `mentorcast.${mentorcastId}.comments`,
      `mentorcast.${mentorcastId}.likes`
    ];
    for (const event of events) {
      Socket().off(event);
    }
  },
  methods: {
    setPage(page: any) {
      this.page = page;
    },
    onTab(tab: any) {
      this.data = [];
      this.currentTab = tab;
      this.getComments(this.page);
    },
    getComments(page: any) {
      this.$store
        .dispatch("comment/GET_COMMENTS", {
          type: this.isMentorcast ? "mentorcast" : "post",
          id: this.$route.params.id,
          tab: this.currentTab,
          page
        })
        .then(data => {
          this.page = page;
          this.load_more = page < data.last_page;
          this.data.push(...data.data);
        });
    },
    onLoadMore() {
      this.page++;
      this.getComments(this.page);
    },
    updateLike(id: any, counts: any) {
      const index = this.data.findIndex((i: any) => i.id == id);
      if (index > -1) {
        this.data[index].like_counts = counts;
      }
    },
    addNewComment(comment: any) {
      console.log("addNewComment");
      this.data.unshift(comment);
      this.$emit("refreshPost");
    }
  }
});
