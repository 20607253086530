
import { Comment } from "@/types/interfaces";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Comment",
  props: {
    comment: {
      type: Object as () => Comment
    },
    isMentorcast: {
      type: Boolean
    },
    canComment: {
      type: Boolean
    }
  },
  data() {
    return {
      // Comment reactions buttons
      reactions: [
        {
          name: "agree",
          backgroundForBar: "#e2f0d9",
          icon: "clap"
        },
        {
          name: "sceptic",
          backgroundForBar: "#ffe699",
          icon: "sceptic"
        },
        {
          name: "disagree",
          backgroundForBar: "#c00000",
          icon: "disagree"
        }
      ]
    };
  },
  computed: {
    getMax() {
      //@ts-ignore
      return Object.values(this.comment.like_counts).reduce((a, b) => a + b);
    },
    ...mapGetters({
      profile: "profile/getProfile",
      mentorcastUser: "mentorcast/getMentorcast"
    })
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      } else {
        return `/mentorees/${data.uuid}`;
      }
    },
    formatDesc(text: string): string {
      const urlPattern = /https?:\/\/\S+|www\.\S+/g;

      const replacedText = text.replace(urlPattern, match => {
        return `<a href="${match}" target="_blank" style="color: #4E5D78;text-decoration: underline">${match}</a>`;
      });
      return replacedText;
    },
    likeable(type: string, id: string) {
      this.$store
        .dispatch("likeable/SET_LIKE", {
          likeType: "comment",
          id: id,
          data: {
            type,
            like: true
          }
        })
        .then(data => {
          this.$emit("updateLike", id, data.like_counts);
        });
    }
  }
});
